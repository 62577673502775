<!--
 * @version: 1.0
 * @Date: 2023-05-27 16:59:48
 * @LastEditors: 杨慢慢
-->
<template>
  <div class="insert">
    <!-- allDisabled 操作日志只支持预览 -->
    <a-popover
      placement="bottomLeft"
      :trigger="allDisabled ? '' : 'click'"
      v-model:visible="visible"
      overlayClassName="insert-pop"
    >
      <template #content>
        <div class="node-select">
          <div @click.stop="addApprovalNode" style="margin-bottom:12px">
            <i class="iconfont icon-shenpiren" />
            <span>审批人</span>
          </div>
          <div @click="addConditionsNode">
            <i class="iconfont icon-fenzhitiaojian" />
            <span>条件分支</span>
          </div>
        </div>
      </template>
      <a-button shape="circle" class="btn" :disabled="allDisabled">
        <span class="iconfont icon-tianjiatiaojian"></span>
      </a-button>
    </a-popover>
  </div>
</template>

<script>
import { PlusOutlined, ClockCircleOutlined } from '@ant-design/icons-vue'
export default {
  name: 'InsertButton',
  components: { PlusOutlined, ClockCircleOutlined },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  methods: {
    addApprovalNode() {
      // console.log(111111)
      this.visible = false
      this.$emit('insertNode', 'APPROVAL')
    },
    addConditionsNode() {
      this.visible = false
      this.$emit('insertNode', 'CONDITIONS')
    }
  }
}
</script>

<style lang="less" scoped>
.node-select {
  div {
    cursor: pointer;
    padding: 10px 15px;
    border: 1px solid #f8f9f9;
    background-color: #f8f9f9;
    border-radius: 10px;
    width: 130px;
    position: relative;
    span {
      position: absolute;
      left: 65px;
      top: 18px;
    }
    // &:hover {
    //   background-color: #fff;
    //   box-shadow: 0 0 8px 2px #d6d6d6;
    // }
    i {
      font-size: 25px;
      padding: 5px;
      border: 1px solid #dedfdf;
      border-radius: 14px;
      color: #b5b5b5;
    }
  }
}
.node-select {
  width: 290px;
}
.icon-tianjiatiaojian {
  color: #c1c1c1;
  font-weight: 700;
}
.btn {
  border: none;
}
.popover {
  width: 152px;
}
</style>
<style lang="scss">
.insert-pop {
  .ant-popover-content {
    border-radius: 10px;
    width: 166px;
    padding: 4px 0px;
    border-radius: 6px;
    margin-left: 40px;
    margin-top: -40px;
  }
  .ant-popover-inner {
    border-radius: 10px;
  }
  .ant-popover-arrow {
    display: none;
  }
}
</style>
